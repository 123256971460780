<template>
  <span>{{ Math.round(animatedValue) }}</span>
</template>

<script>
export default {
  props: {
    startValue: {
      type: Number,
      default: 0,
    },
    endValue: {
      type: Number,
      required: true,
    },
    decimalValue: { 
      type: Boolean
    }
  },

  data() {
    return {
      animatedValue: this.decimalValue ? 0.00 : 0,
      duration: 1000 // duration in milliseconds
    };
  },
  
  watch: {
    endValue(value) {
      if (value === 0) return;

      this.tweenValue();
    }
  },

  methods: {
    tweenValue() {
      const startTime = performance.now();

      const animate = (currentTime) => {
        const elapsedTime = currentTime - startTime;
        const progress = Math.min(elapsedTime / this.duration, 1);
        this.animatedValue =
          this.startValue + (this.endValue - this.startValue) * progress;

        if (progress < 1) {
          requestAnimationFrame(animate);
        }
      };

      requestAnimationFrame(animate);
    }
  }
};
</script>
